
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";


// import { AccountRepository, RepositoryFactory } from "@/lib/https";
// import { storage } from "@/lib/storage";


export default defineComponent({
  name: "Maintenance",
  components: {
    
  },
  mounted() {

  },
  data() {
    return {
      
    }
  },
  setup() {
    const state = reactive({
      state1: [],
    });

    return { ...toRefs(state) };
  },
  methods: {
   
    },
});

