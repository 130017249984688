
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";

import DashboardMain from './dasboard_main.vue';
import OffServer from './offserver.vue';
import Result from './result.vue';

import RepositoryFactory from "@/lib/https/repositoryFactory";
import AuthRepository from "@/lib/https/authRepository";

// import { AccountRepository, RepositoryFactory } from "@/lib/https";
// import { storage } from "@/lib/storage";


export default defineComponent({
  name: "DashBoard",
  components: {
    DashboardMain,
    Result,
    OffServer,
  },
  
  data() {
    return {
      status: -1,
      isShow: true,
      isShowMainResult: true,
      isShowResultTrans: false,
      data: {} as any,
      typeDoc: '',
      typeLanguage: '',
      typeExc: '',
      language: '',
    }
  },
  setup() {
    const state = reactive({
      state1: [],
    });

    return { ...toRefs(state) };
  },
  mounted() {
    this.loadTypeDoc();
  },

  methods: {
    
    async loadTypeDoc() {
      const { type } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
      const res = await type();
      if(res == undefined) {
        this.status = 2;
      } else {
        this.status = 0;
      }
    },

    onEnlargeText: function(param: any) {  
      this.status = param.status;
      this.data = param.data;
      if(param.event == true || param.language != 'vi') {
        this.typeDoc = 'Rút trích văn bản';
      } else {
        this.typeDoc = param.doctype;
      }
      this.typeLanguage = param.language;
      this.typeExc = param.event;
    },

  },
});

