
import { defineComponent } from 'vue'
import RepositoryFactory from "@/lib/https/repositoryFactory";
import AuthRepository from "@/lib/https/authRepository";


export default defineComponent({
    name: 'dropdown',
    props: ['`${uploadFile}`', 'data', 'typef', 'textTrans'],
    data() {
        return {
            isOpen: false,
        }
    },
    setup() {
        
    },

    mounted() {
    },

    updated() {
    },

    methods: {
        async selectedTypef(event: any) {
            var typefFile = '';

            const { file } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
            const form = new FormData();

            if(event == 0) {
                typefFile = 'excel';
                form.append("obj", JSON.stringify(this.data));
            } else if(event == 1) {
                typefFile = 'csv';
                form.append("obj", JSON.stringify(this.data));
            } else {
                typefFile = 'txt';
                if(this.typef == true && this.textTrans != "") {
                    console.log("textTrans",this.textTrans);
                    form.append("obj", this.textTrans);
                } else {
                    var text = '';
                    console.log(this.data.text);
                    if (this.data.text != undefined){
                        for(const key in this.data.text) {
                            text = text.concat(this.data.text[key].editText, "\n");
                        }
                    }
                    else{
                        for(const key in this.data.text_A) {
                            text = text.concat(this.data.text_A[key].editText, "\n");
                        }
                        text = text.concat("\n");
                        for(const key in this.data.text_B) {
                            text = text.concat(this.data.text_B[key].editText, "\n");
                        }
                    }
                    
                    form.append("obj", text);
                }
            }

            
            form.append("typef", typefFile);

            const res = await file(form);

            // var name = res?.data;
            // var nameFile = name.substring(name.lastIndexOf("/") + 1, name.length);

            var fileLink = document.createElement('a');

            console.log(fileLink);

            fileLink.href = res?.data;
            
            fileLink.setAttribute("target", "new");
            fileLink.setAttribute("download", "");
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
        }
    },

})
