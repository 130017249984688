
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";

//import buttonResult from './button_result.vue';
//import Enum from "@/models/Enum";
import dropdown from './dropdown.vue';
import RepositoryFactory from "@/lib/https/repositoryFactory";
import AuthRepository from "@/lib/https/authRepository";

// import { AccountRepository, RepositoryFactory } from "@/lib/https";
// import { storage } from "@/lib/storage";

export default defineComponent({
  name: "result",
  components: {
    //buttonResult,
    dropdown,
  },

  props: ['result_data', 'type', 'language', 'exc'],

    data() {
        return {
            selected: {},
            checkTypeFile: true, 
            checkTypeData: true,
            typeTable: false,
            btnTranslate: true,
            hiden: true,
            newLang: false,
            langTranslateType: [] as any[],
            langTranslateDoc: [] as any[],
            arrData: [] as any[],
            objTranslate: {} as any,
            objFile: {} as any,
            textTranslate: '',
            fileText: false,
            text: '',
            copyShow: false,
        }
    },
  emits: ['ShowHidenPage'],
  
    updated() {
        this.btnTranslate = false;
        if(this.selectedType === 0) //table
        {
            this.checkTypeData = true; 
            //this.btnTranslate = false;
            this.fileText = false;
        }
        else {
            this.checkTypeData = false;
            this.fileText = true;
        }

        this.$i18n.locale = this.language; //vi en ja

        if ((this.$i18n.locale != "vi") &&  (this.$i18n.locale != "")) {
            this.hiden = false;
        } else {
            this.hiden = true;
        }

        this.objFile = this.result_data[this.selectedImage];

      
    },

  mounted() {

    this.loadLanguageTranslate();

    for(const key in this.result_data) {
        this.arrData.push(key);
    }
    
    if(this.exc === true)
    {
        this.typeTable = true;
    } 
    
    if(this.type === 'Hợp đồng kinh tế') {
        this.checkTypeFile = false;
    }

   
  },
  
  setup() {
      const state = reactive({
        selectedImage: 0,
        selectedType: 0,
        selectedDocLanguageTrans: 0,
    });

    const onChangeImage = (event: any) => {
      state.selectedImage = Number(event.target.value);
    };

    const onChangetypeData = (event: any) => {
        state.selectedType = Number(event.target.value);
    };
    const onChangeLanguageTrans = (event: any) => {
        state.selectedDocLanguageTrans = Number(event.target.value);
    }; 

    return { ...toRefs(state), onChangeImage, onChangetypeData, onChangeLanguageTrans};
  },
  methods: {
      changeText(e:any , val:any) {
        val.editText = e.target.value
        // console.log(this.result_data[this.selectedImage].text_A);
        // console.log(this.result_data[this.selectedImage].text_B);
        // console.log(this.result_data[this.selectedImage].text);
    },

    prePage() {
        this.$emit('ShowHidenPage', {"status": 0});
        this.$forceUpdate();
    },

    onChangeLangTrans() {
        this.$emit('ShowHidenPage', {"status": 2});
    },

    async ChangeLanguage() {
        if(this.selected === Object(this.selected)) {
            alert("Please select language to translate!");
        } else {
            this.selectedType = 1;
            const { googleTranslate } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
            this.newLang = true;
            this.fileText = true;

            this.textTranslate = '';

            for(const i in this.result_data) {
                this.objTranslate[i] = {};
                var data = this.result_data[i].text;
                var data1 = this.result_data[i].text_A;
                var data2 = this.result_data[i].text_B;

                
                
                if(this.checkTypeFile == true) {
                   
                    var textLang =  '';
                    for(const key in data) {
                        textLang = textLang.concat( data[key].editText, " ");
                    }

                    const form = new FormData();
                    form.append("text", textLang);
                    form.append("from", this.$i18n.locale);
                    form.append("to", this.langTranslateType[this.selectedDocLanguageTrans]);
                    const res = await googleTranslate(form);

                    var array = res?.data;
                    
                    this.objTranslate[i]["text"] =  array.replace(/;/g," ");
                    this.textTranslate = this.objTranslate[i].text;
                    
                } else {
                    
                    var textLangA =  '', textLangB =  '';
                    for(const key in data1) {
                        textLangA = textLangA.concat(data1[key].editText, " ");
                    }


                    for(const key in data2) {
                        textLangB = textLangB.concat(data2[key].editText, " ");
                    }


                    const form1 = new FormData();
                    const form2 = new FormData();
                    form1.append("text", textLangA);
                    form1.append("from", this.$i18n.locale);
                    form1.append("to", this.langTranslateType[this.selectedDocLanguageTrans]);
                    const res1 = await googleTranslate(form1);

                    form2.append("text", textLangB);
                    form2.append("from", this.$i18n.locale);
                    form2.append("to", this.langTranslateType[this.selectedDocLanguageTrans]);
                    const res2 = await googleTranslate(form2);

                    var array1 = res1?.data;
                    
                    var array2 = res2?.data;
                   

                    this.objTranslate[i]["text_A"] = array1.replace(/;/g," ");

                    this.textTranslate = this.textTranslate.concat(this.objTranslate[i].text_A, "\n");

                    this.objTranslate[i]["text_B"] = array2.replace(/;/g," ");

                    this.textTranslate = this.textTranslate.concat("\n", this.objTranslate[i].text_B);
                }
                
            }
        }

        //console.log(this.objTranslate);
        
    },

    async loadLanguageTranslate() {
        const { languageTranslate } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
        const langTrans = await languageTranslate();
        // this.langTranslateType.push("none");
        // this.langTranslateDoc.push("");
        for(const key in langTrans?.data) {
            this.langTranslateType.push(`${key}`);
            this.langTranslateDoc.push(`${langTrans?.data[key]}`)
        }
        // console.log(this.langTranslateDoc])
    },

    copyToClipboard: function() {
        if(this.newLang == true) {
            navigator.clipboard.writeText(this.objTranslate[this.selectedImage].text);
            navigator.clipboard.readText().then(clipText => console.log(clipText));

        } else {
        var textCopy = '';
        if(this.checkTypeFile == true) {
            for(const key in this.result_data[this.selectedImage].text) {
                    textCopy = textCopy.concat( this.result_data[this.selectedImage].text[key].editText, "\n");
                }
        } else {
            for(const key in this.result_data[this.selectedImage].text_A) {
                    textCopy = textCopy.concat( this.result_data[this.selectedImage].text_A[key].editText, "\n");
                }
                for(const key in this.result_data[this.selectedImage].text_B) {
                    textCopy = textCopy.concat( this.result_data[this.selectedImage].text_B[key].editText, "\n");
                }
        }


            navigator.clipboard.writeText(textCopy);
            navigator.clipboard.readText().then(clipText => console.log(clipText));
        }
        this.copyShow = true;
        console.log('copyShow', this.copyShow);
       setTimeout(() => {this.copyShow = false; console.log('copyShow', this.copyShow);}, 2000);
    },

    onClickReset: function() {
       this.newLang = false;
       this.fileText = false;
    //    this.selectedDocLanguageTrans = 0;
       this.textTranslate = "";
       this.selected = {}
    
    
    this.$forceUpdate();
    // contract
    if (this.result_data[0].text == undefined){
        for(const i in this.result_data) {
            var dataA = this.result_data[i].text_A;
            var dataB = this.result_data[i].text_B;
            for (const j in dataA) {
                dataA[j].editText = dataA[j].text
            }
            for (const j in dataB) {
                dataB[j].editText = dataB[j].text
            }
            // console.log(data)
        }
    }
    // other else
    else {
        for(const i in this.result_data) {
            var data = this.result_data[i].text;
            for (const j in data) {
                data[j].editText = data[j].text
            }
            // console.log(data)
        }
    }
    
    },
    
  },
});

