
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";
//import Enum from "@/models/Enum";
//import func from "vue-temp/vue-editor-bridge";

import AuthRepository from "@/lib/https/authRepository";
import RepositoryFactory from "@/lib/https/repositoryFactory";
import { useStore } from "vuex";
import ProgressBar from "./progress.vue";
// import { useRoute, useRouter } from "vue-router";
//import result from './result.vue';
// import { AccountRepository, RepositoryFactory } from "@/lib/https";
// import { storage } from "@/lib/storage";

export default defineComponent({
  name: "dashBoard_main",
  components: {
    //result,
    ProgressBar,
  },

  props: ["language"],

  data() {
    return {
      ulr: "",
      isDisable_file: false,
      fileValue: false,
      fileList: [] as any[],
      fileExist: false,
      allowFile: ["png", "jpg", "jpeg", "pdf"],
      tableTypeDoc: [] as any[],
      languageDoc: [] as any[],
      languageType: [] as any[],
      scrollFile: false,
      main_data: {} as any,
      hiden: true,
      lang: "",
      t: "",
      progressBar: false,
    };
  },

  created() {
    const store = useStore();
    console.log(store);
  },

  updated() {
    if (this.ulr.length > 0) {
      this.isDisable_file = true;
      this.fileExist = false;
    } else {
      this.isDisable_file = false;
    }

    if (this.fileList.length > 0) {
      this.fileValue = true;
      this.fileExist = false;
    } else {
      this.fileValue = false;
    }
    if (this.fileList.length > 13) {
      this.scrollFile = true;
    }

    if (this.$i18n.locale != "vi" && this.$i18n.locale != "") {
      this.hiden = false;
    } else {
      this.hiden = true;
    }

    this.getType();
  },
  emits: ["ShowHidenPage"],
  mounted() {
    this.loadTypeDoc();
    this.loadlanguage();
  },

  setup() {
    const store = useStore();
    // const router = useRouter();
    // const route = useRoute();
    const state = reactive({
      selectedDocType: 0,
      selectedDocLanguage: 0,
      showInput: false,
      slice: 2,
      index: 2,
      //test: store.getters["main_datalate/getTest"]
    });

    // const CMND = Enum.DocType.CMND;
    // const CCCD = Enum.DocType.CCCD;
    // const HDKT = Enum.DocType.HDKT;

    const onChangeDocType = (event: any) => {
      state.selectedDocType = Number(event.target.value);
      //   FIXME
      state.showInput = event.target.value == 7;
      //console.log(state.selectedDocType);
    };

    return { ...toRefs(state), onChangeDocType, store };
  },
  watch: {
    $route(to) {
      //console.log(this.$i18n, to.params.lang);
      this.$i18n.locale = to.params.lang;
    },
  },
  methods: {
    onChangeLanguage(event: any) {
      this.selectedDocLanguage = Number(event.target.value);
      const langCode = this.languageType[this.selectedDocLanguage];
      // console.log(langCode);
      this.$router.push({ name: "Home", params: { lang: langCode } });
    },

    async loadTypeDoc() {
      const { type } =
        RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
      const res = await type();

      for (const key in res?.data) {
        this.tableTypeDoc.push(`${res?.data[key].view}`);
      }

      this.main_data = res?.data;
    },

    async loadlanguage() {
      this.selectedDocLanguage = 0;
      const { language } =
        RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
      const dataLang = await language();
      const paramslang = this.$route.params.lang as string;
      let index = 0;
      for (const key in dataLang?.data) {
        if (paramslang === dataLang?.data[key].code) {
          this.selectedDocLanguage = index;
        }
        this.languageType.push(dataLang?.data[key].code);
        this.languageDoc.push(dataLang?.data[key].lang);
        index += 1;
      }
      this.$i18n.locale = paramslang;
    },

    getType: function () {
      if (this.selectedDocLanguage != 0) {
        this.main_data[Number(this.t)].exc = true;
      } else {
        for (const key in this.main_data) {
          if (
            this.main_data[key].view == this.tableTypeDoc[this.selectedDocType]
          ) {
            this.t = key.toString();
          }
        }
      }
    },

    checkFile: function (file: any) {
      var str = file.split(".").reverse();
      for (var i = 0; i < this.allowFile.length; i++) {
        if (str[0].toLowerCase() == this.allowFile[i]) {
          return true;
        }
      }
      return false;
    },

    onChangeFile: function (event: any) {
      var droppedFiles = event.target.files || event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        this.fileValue = true;
      }
      for (var i = 0; i < droppedFiles.length; i++) {
        if (!this.checkFile(droppedFiles[i].name)) {
          //this.tailFile = true;
          alert(
            "Type of file is invalid. System just support for JPG, PNG, JPEG, PDF, please select the other!"
          );
          return;
        }
        this.fileList.push(droppedFiles[i]);
      }
    },

    async nextPage() {
      //this.$i18n.locale = 'ja';
      if (this.fileValue === true || this.isDisable_file === true) {
        this.progressBar = true;
        const { image } =
          RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
        const form = new FormData();
        //var t = this.selectedDocType;
        // if(this.languageType[this.selectedDocLanguage] != 'vi') {
        //   this.t = '1';
        // }
        form.append("type", this.t);

        //console.log(this.selectedDocLanguage);
        //console.log(this.languageType);
        if (this.t == "9") {
          form.append("slice", "" + this.slice);
          form.append("index", "" + this.index);
          form.append("language", "en");
        } else {
          form.append("language", this.languageType[this.selectedDocLanguage]);
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach((file) => {
            form.append("files", file);
          });
        } else {
          form.append("url", this.ulr);
        }
        console.log(form, this.store);
        const response = await image(form, this.store);
        // console.log(response)
        if (response == null) {
          alert(
            "The system is overloaded, please wait a minute, refresh page and try again."
          );
          this.progressBar = false;
          return;
          //
        }
        this.$emit("ShowHidenPage", {
          status: 1,
          data: response?.data,
          doctype: this.tableTypeDoc[this.selectedDocType],
          language: this.languageType[this.selectedDocLanguage],
          event: this.main_data[Number(this.t)].exc,
        });
      } else {
        this.fileExist = true;
      }
    },

    dragover: function (event: any) {
      event.preventDefault();

      if (!event.currentTarget.classList.contains("bg-green")) {
        event.currentTarget.classList.remove("bg-gray");
        event.currentTarget.classList.add("bg-green");
      }
    },

    dragleave: function (event: any) {
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },

    drop: function (event: any) {
      event.preventDefault();
      this.onChangeFile(event);
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },

    remove: function (i: any) {
      this.fileList.splice(i, 1);
    },
  },
});
